
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");


:root {
  --btn-primary: rgba(10, 179, 156, 1);
  --btn-primary-hover: #099885;
  --theme-primary: #5088a9;
  --light: #d0cfcf;
  --btn-light: rgba(224, 226, 229, 1);
  --light-gray: #c7c6c6;
  --secondry-light: #8e8b8b;
  --bg-light: #eef0f2;
  --bg-dark: #405189;
  --white: #ffff;
  --gray-900: #020f29;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary-btn {
  background-color: var(--btn-primary) !important;
  border: none !important;
  padding: 8px 16px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  border-radius: 4px !important;
  color: rgba(255, 255, 255, 1) !important;
  &:hover {
    background-color: var(--btn-primary-hover) !important;
  }
}
.success-btn {
  font-size: 10px !important;
  background-color: rgba(10, 179, 156, 0.16) !important;
  color: #0ab39c !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
  padding: 8px 12px !important;
}
.success1-btn{
  font-weight: 600 !important;
  font-size: 10px !important;
  color: rgba(106, 218, 125, 1) !important;
  background-color: rgba(106, 218, 125, 0.32) !important;
  padding: 8px 12px 8px 12px !important;
  line-height: 15px !important;
  border-radius: 4px !important;
}

.secondary-btn {
  background-color: var(--btn-light) !important;
  border: none !important;
  padding: 12px 16px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  border-radius: 4px !important;
  color: rgba(164, 168, 178, 1) !important;
}


.company{
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: rgba(2, 15, 41, 1) !important;
}
.sidebar {
  /* width: 300px;   */
  background-color: var(--theme-primary);
}

.username {
  font-size: 14px;
  font-weight: 700;
  color: rgba(2, 15, 41, 1);
  line-height: 21px;
}
.user-role {
  font-size: 14px;
  font-weight: 400;
  opacity: 40%;

  color: rgba(2, 15, 41, 1);
  line-height: 21px;
}
.menu-tag {
  font-size: 10px;
  font-weight: 700;
  color: var(--light);
}
.menu-item {
  font-size: 14px;
  font-weight: 400;
  transition: all 0.2s ease;
  > a {
    color: var(--light-gray);
  }
}
.activMenu {
  /* font-weight: 600; */
  > a {
    color: var(--white);
  }
}

.logout-btn {
  cursor: pointer;
  border: 1px solid rgba(224, 0, 0, 0.2);
  border-radius: 4px !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: rgba(224, 0, 0, 0.5);
  }
}
.wrapper {
  background-color: var(--bg-light);
  height: calc(100vh - 78px);
  overflow: auto;
}
.time-card {
  background-color: var(--bg-dark) !important;
  .time-btn {
    font-size: 10px !important;
    padding: 8px !important;
    border-radius: 8px !important;
    font-weight: 600 !important;
    line-height: 15px !important;
    color: var(--bg-dark) !important;
  }
}
.primary-light {
  color: var(--light);
}
.secondary-light {
  color: var(--secondry-light);
}



.card-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  
}
.title-light{
  color: rgba(255, 255, 255, 1) !important;
  opacity: 64%;
}
.title-dark{
  color: rgba(2, 15, 41, 1) !important;
  opacity: 64%;
}
.card-value {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.value-light{
  color: rgba(255, 255, 255, 1);
}
.value-dark{
  color: rgba(2, 15, 41, 1);
}

/* Table Classes */
.table-header{
  font-size: 20px;
  color: rgba(2, 15, 41, 1);
  font-weight: 600;
  line-height: 30px;
}
.table-title{
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: rgba(2, 15, 41, 1);
}
.rdt_TableCell {
  font-size: 14px;
  font-weight: 400;
  color: rgba(2, 15, 41, 1);
  line-height: 21px;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(2, 15, 41, 1);
  line-height: 30px;
}
.sub-title {
  color: rgba(2, 15, 41, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.input-label, .input-field{
  color: rgba(2, 15, 41, 1) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}
.error{
  font-size: 12px;
}
.list-item {
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid var(--bg-light);

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    /* height: 24px;
    width: 24px; */
    border-radius: 4px;
    padding: 8px;
    border: none;
    background-color: rgba(241, 113, 113, 0.3);
    color: #f17171;
  }
}
